<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="px-2">
        {{ $t('report_assessment') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="serachReport" />
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model.trim="searchtext"
            dense
            outlined
            :label="$t('search')"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="(item,index) in columns"
                :key="index"
                class="text-left"
                width="200"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!dataTableList.length">
              <td
                :colspan="columns.length"
                class="text-center"
              >
                <span>
                  {{ $t('no_information') }}
                </span>
              </td>
            </tr>
            <tr v-else>
              <td
                v-for="(item,index) in columns"
                :key="index"
                width="200"
              >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import useDocsReport from './useDocsReport'

export default {
  components: {
    DateFilters,
  },
  setup() {
    return {
      ...useDocsReport(),
      icons: {
        mdiFileExcelOutline,
      },
    }
  },
}
</script>
