import { ref, watch } from '@vue/composition-api'
import { sumdate } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import { removeComma, reportAssessment } from '@/views/reports/useExcel'

export default function useDocsReport() {
  const searchtext = ref('')
  const XLSX = require('xlsx')
  const dataTableList = ref([])
  const columns = ref([
    '#',
    i18n.t('code'),
    i18n.t('customer_patient'),
    i18n.t('staff'),
    i18n.t('doctor'),
    i18n.t('course'),
    i18n.t('course_score'),
  ])
  const options = ref({})
  const loading = ref(true)
  const payload = ref({})
  const report = ref({})
  const exportLoading = ref(false)
  const dateStart = ref('')
  const dateEnd = ref('')

  const serachReport = async (start, end) => {
    loading.value = true
    if (start) {
      dateStart.value = start
    }
    if (end) {
      dateEnd.value = end
    }
    payload.value = {
      searchtext: searchtext.value,
      start: dateStart.value,
      end: dateEnd.value,
      lang: i18n.locale,
    }
    const { data, question } = await reportAssessment(payload.value)
    question.forEach(i => {
      columns.value.push(i.question_name)
    })
    columns.value.push(i18n.t('adjunct'))
    dataTableList.value = data || []
    loading.value = false
  }
  const exportExcel = async () => {
    const { start, end } = payload.value
    let dataExport = dataTableList.value.map((item, i) => {
      delete item.transfer_id_pri

      return { number: i + 1, ...item }
    })
    dataExport = await removeComma(
      JSON.parse(JSON.stringify(dataExport)),
    )
    const fileName = `${i18n.t(
      'ListOfMedicineEquipmentTransfers',
    )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
      'to',
    )} ${sumdate(end)}.xlsx`

    // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
    /*  รายงานวิเคราะห์รายการตรวจ */
    const Heading = [
      [
        `${i18n.t('ListOfMedicineEquipmentTransfers')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}`,
      ],
      [
        ...columns.value,
      ],
    ]
    const ws = XLSX.utils.aoa_to_sheet(Heading)
    XLSX.utils.sheet_add_json(ws, dataExport, {
      header: [
        'number',
        'transfer_id',
        'shop_name',
        'transfer_price',
        'transfer_create',
        'transfer_status_name',
        'user_fullname',
      ],
      skipHeader: true,
      origin: -1,
    })
    const wb = XLSX.utils.book_new()
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: columns.value.length - 1 } }]
    const wscols = [
      { wch: 6 },
      { wch: 15 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ]
    ws['!merges'] = merge
    ws['!cols'] = wscols
    XLSX.utils.book_append_sheet(wb, ws, 'sheet')

    /* พิมร์ files */
    XLSX.writeFile(wb, fileName)

    setTimeout(() => {
      exportLoading.value = false
    }, 1500)

    watch([searchtext], () => {
      serachReport()
    })
  }

  watch([searchtext], () => {
    serachReport()
  })

  return {
    searchtext,
    dataTableList,

    columns,
    options,
    loading,
    payload,
    report,
    exportLoading,
    serachReport,
    exportExcel,
  }
}
